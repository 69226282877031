$color-base-white: #fff;
$color-base-50: #f8fafc;
$color-base-100: #f1f5f9;
$color-base-200: #e2e8f0;
$color-base-300: #cbd5e1;
$color-base-400: #94a3b8;
$color-base-500: #64748b;
$color-base-600: #475569;
$color-base-700: #334155;
$color-base-800: #1e293b;
$color-base-900: #0f172a;
$color-red-100: #fee2e2;
$color-red-400: #f87171;
$color-red-500: #ef4444;
$color-red-600: #dc2626;
$color-red-800: #991b1b;
$color-yellow-100: #fef3c7;
$color-yellow-400: #facc15;
$color-yellow-500: #eab308;
$color-yellow-600: #ca8a04;
$color-yellow-800: #92400e;
$color-green-100: #d1fae5;
$color-green-400: #4ade80;
$color-green-500: #22c55e;
$color-green-600: #16a34a;
$color-green-800: #065f46;
$color-blue-100: #dbeafe;
$color-blue-400: #60a5fa;
$color-blue-500: #3b82f6;
$color-blue-600: #2563eb;
$color-blue-800: #1e40af;
$color-indigo-100: #e0e7ff;
$color-indigo-400: #818cf8;
$color-indigo-500: #6366f1;
$color-indigo-600: #4f46e5;
$color-indigo-800: #3730a3;
$color-purple-100: #ede9fe;
$color-purple-400: #c084fc;
$color-purple-500: #8b5cf6;
$color-purple-600: #9333ea;
$color-purple-800: #5b21b6;
$color-pink-100: #fce7f3;
$color-pink-400: #f472b6;
$color-pink-500: #ec4899;
$color-pink-600: #db2777;
$color-pink-800: #9d174d;
$color-success-shade: #136d34;
$color-success: #22c55e;
$color-success-tint: #51e186;
$color-error-shade: #bc1010;
$color-error: #ef4444;
$color-error-tint: #f58a8a;
$color-warning-shade: #856605;
$color-warning: #eab308;
$color-warning-tint: #f9cd44;
$color-warning-bg: #fef3c7;
$color-text: #1e293b;
$color-headings: $color-base-700;
$color-borders: #e2e8f0;
$color-placeholder-bg: #e2e8f0;
