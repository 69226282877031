$font-weight-regular: 500;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-bolder: 900;
$font-size-xxs: 0.75rem; // 12px
$font-size-xs: 0.875rem; // 14px
$font-size-s: 1.125rem; // 18px
$font-size-m: 1.25rem; // 20px
$font-size-l: 1.375rem; // 22px
$font-size-xl: 2.125rem; // 34px
$font-size-labels: 1rem; // 16px
$font-size-xxl: 2.25rem;
$line-height-xxs: 1.25rem; // 20px
$line-height-xs: 1.375rem; // 22px
$line-height-s: 1.75rem; // 28px
$line-height-m: 1.75rem; // 28px
$line-height-l: 2.125rem; // 34px
$line-height-xl: 2.75rem; // 44px
$line-height-labels: 1.375rem; // 22px
